<template>
  <div>
    <div v-if="isLoadingImage" class="text-center">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">Loading...</strong>
    </div>
    <div v-else>
      <div class="panel-upload" v-if="images">
        <div class="panel-bg-file-img">
          <span
            class="icon-delete"
            color="#FFFFFF"
            @click.prevent="deleteImage"
            v-if="images"
            >x</span
          >

          <pdf
            v-if="images.includes('.pdf')"
            :src="images"
            class="w-100 shadow"
          >
          </pdf>
          <img v-else :src="images" class="w-100 shadow" ratio="16/9" />
        </div>
      </div>
      <div class="div-input" v-else>
        <div :class="['input-custom', { error: isValidate }]">
          <div class="d-flex justify-content-center align-items-center mt-1">
            <div class="w-100 reletive">
              <input
                :class="['custom-input']"
                type="file"
                accept="image/*,application/pdf"
                name="image"
                :required="required"
                :value="fileName"
                ref="upload"
                v-on:change="handleFileChange($event)"
                capture="camera"
              />

              <div class="text-upload" @click="$refs.upload.click()">
                <font-awesome-icon
                  :icon="['fas', 'camera']"
                  class="mr-2 color-primary"
                />
                เพิ่มรูปภาพ
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
  },
  props: {
    required: {
      required: false,
      type: Boolean,
    },
    id: {
      required: false,
    },
    name: {
      required: false,
      type: String,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    label: {
      required: false,
      type: String,
    },
    img: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      images: this.img,
      fileName: this.img,
      value2: this.img,
      isLoadingImage: false,
      showPreview: "",
      type: {
        all: ["image/jpeg", "image/png", "video/mp4"],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"],
        video: ["video/mp4"],
      },
      modalMessage: "",
      modalDesc: "",
    };
  },

  methods: {
    handleImage(img) {
      this.fileName = img.name;
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = `https://api-hourglass-admin-dev.dosetech.co/${await this.saveImagetoDb(
          reader.result,
          img.type
        )}`;
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = this.images;
        this.$emit("input", this.images);
        this.$emit("update:value", this.images);
        this.$emit("setFileName", img.name);
      };
    },
    deleteImage() {
      this.images = "";
      this.fileName = "";
      this.showPreview = "";
      this.$emit("input", "");
      this.$emit("update:value", "");
    },
    async saveImagetoDb(img, type) {
      this.loadingImage = false;
      const form = {
        Base64String: img,
        is_file: type.includes("pdf"),
        is_video: false,
      };
      return await this.$axios
        .post(`${this.$baseUrl}/customer/UploadImage`, form)
        .then(async (data) => {
          this.isLoading = false;
          if (data.result == 1) {
            return data.detail;
          }
        });
    },
    chkKeyDown(e) {
      e.preventDefault();
    },
    clearValue(e) {
      e.target.value = "";
      this.value = "";
      this.fileName = "";
      this.images = "";
      this.$emit("deleteImage");
    },
    handleFileChange(e) {
      try {
        this.value = e.target.files[0];

        var _validFileExtensions = [
          "image/jpeg",
          "image/png",
          "application/pdf",
        ];

        if (e.target.files.length) {
          // console.log(this.value.size);
          var reader = new FileReader();

          reader.readAsDataURL(e.target.files[0]);

          reader.onload = async () => {
            var resize = null;
            if (this.type.image.includes(this.value.type)) {
              // resize = await resizeImage(reader.result);
            }
            let size = resize ? resize.size : this.value.size;
            var filesize = (size / 1024 / 1024).toFixed(4);
            if (_validFileExtensions.indexOf(this.value.type) < 0) {
              // this.$refs.input.value = "";
              this.value = "";
              this.$emit("input", "");
              this.$emit("update:value", "");
              this.modalMessage = "File not support";
              this.modalDesc = "(Only .png, .jpg, .pdf)";
              // alert("File not support");
              this.$refs.modalError.show();
            } else if (filesize > 2.5) {
              this.modalMessage = "This file size is too large";
              this.modalDesc = "(Maximun Size is 2.5mb)";
              this.$emit("input", "");
              this.$emit("update:value", "");
              this.$refs.modalError.show();
            } else {
              this.handleImage(this.value);
            }
          };
        } else {
          this.value = "";
          // this.$refs.input.value = "";
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.panel-upload {
  position: relative;
}
.icon-add {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  height: 25px;
}
.icon-delete {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  width: 28px;
  font-size: var(--text-lg);
  height: 28px;
  background: black;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 1;
}
.panel-bg-file-img {
  img {
    width: 100%;
    height: auto;
    // aspect-ratio: 1;
    border-radius: 0.5rem;
  }
  position: relative;

  /* padding-bottom: 20%; */
  height: auto;
  // aspect-ratio: 1;
  border: 1px dashed var(--primary-color);
  // background-color: var(--secondary-color);ฟ
  color: var(--primary-color);
  border-radius: 6px;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
}
input[type="file"] {
  color: transparent;
}
input[type="file"]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  width: 100%;
  height: 100%;
  line-height: 95px;
  position: absolute;
  /* opacity: 0; */
  display: none;
}

.input-custom {
  padding: 0px;
}

input.custom-input {
  background-color: #ffffff00;
  color: #ffffff00;

  border: 1px dashed var(--primary-color);

  border-radius: 0.5rem;
  padding: 7px 10px;
  min-height: 40px;
}
label[size="lg"].btn-main,
input[size="lg"].custom-input {
  height: 45px;
}
.input-custom.error .custom-input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: var(--text-md);
  font-family: "Kanit-Light";
}
.display-only {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.btn-main {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  padding: 8px 10px;
}
.btn-download-file {
  padding: 0;
  text-decoration: underline;
}
.delete-icon {
  position: absolute;
  right: 5%;
  top: 10px;
}

.ft-14 {
  font-size: var(--text-md);
}
.loading {
  width: 50px;
  margin: 10px 0;
}
.text-upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary-color);
}
.reletive {
  position: relative;
}

.color-primary {
  color: var(--primary-color) !important;
}
</style>
