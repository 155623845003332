const shippingField = {
  user_guid: "",
  address_name: "",
  prefix: "",
  first_name: "",
  last_name: "",
  optional_telephone: "",
  telephone: "",
  country: "",
  address: "",
  building_name: "",
  alley: "",
  road: "",
  sub_district: "",
  district: "",
  province: "",
  zip_code: "",
  shipping_type: 0,
  note: "",
  email: "",
  is_default: 0,
};
const billingField = {
  user_guid: "",
  billing_address_type: 0,
  branch_type: "",
  tax_id: "",
  prefix: "",
  first_name: "",
  last_name: "",
  email: "",
  telephone: "",
  country: "",
  address: "",
  building_name: "",
  alley: "",
  road: "",
  sub_district: "",
  district: "",
  province: "",
  zip_code: "",
  is_default: 0,
};
const contactField = {
  user_guid: "",
  company_name: "",
  position: "",
  prefix: "",
  first_name: "",
  last_name: "",
  email: "",
  telephone: "",
  optional_telephone: "",
  country: "",
  address: "",
  building_name: "",
  alley: "",
  road: "",
  sub_district: "",
  district: "",
  province: "",
  zip_code: "",
  line_id: "",
  facebook: "",
  x: "",
  is_default: 0,
};
const mapContactField = [
  {
    id: 1,
    key: "prefix",
  },
  {
    id: 2,
    key: "first_name",
  },
  {
    id: 3,
    key: "last_name",
  },
  {
    id: 4,
    key: "company_name",
  },
  {
    id: 5,
    key: "position",
  },
  {
    id: 6,
    key: "email",
  },
  {
    id: 7,
    key: "telephone",
  },
  {
    id: 8,
    key: "optional_telephone",
  },

  {
    id: 9,
    key: "country",
  },
  {
    id: 10,
    key: "address",
  },
  {
    id: 11,
    key: "building_name",
  },

  {
    id: 12,
    key: "alley",
  },
  {
    id: 13,
    key: "road",
  },
  {
    id: 14,
    key: "province",
  },

  {
    id: 15,
    key: "district",
  },

  {
    id: 16,
    key: "sub_district",
  },
  {
    id: 17,
    key: "zip_code",
  },

  {
    id: 21,
    key: "is_default",
  },
];
const mapShippingField = [
  {
    id: 1,
    key: "address_name",
  },
  {
    id: 2,
    key: "prefix",
  },
  {
    id: 3,
    key: "first_name",
  },
  {
    id: 4,
    key: "last_name",
  },
  {
    id: 5,
    key: "email",
  },
  {
    id: 6,
    key: "telephone",
  },
  {
    id: 7,
    key: "optional_telephone",
  },
  {
    id: 8,
    key: "country",
  },

  {
    id: 9,
    key: "address",
  },
  {
    id: 10,
    key: "building_name",
  },
  {
    id: 11,
    key: "alley",
  },

  {
    id: 12,
    key: "road",
  },

  {
    id: 15,
    key: "sub_district",
  },
  {
    id: 14,
    key: "district",
  },
  {
    id: 13,
    key: "province",
  },
  {
    id: 16,
    key: "zip_code",
  },
  {
    id: 17,
    key: "shipping_type",
  },
  {
    id: 18,
    key: "note",
  },
  {
    id: 19,
    key: "is_default",
  },
];
const mapBillingField = [
  {
    id: 1,
    key: "billing_address_type",
  },
  {
    id: 2,
    key: "tax_id",
  },
  {
    id: 3,
    key: "prefix",
  },
  {
    id: 4,
    key: "first_name",
  },
  {
    id: 5,
    key: "last_name",
  },
  {
    id: 6,
    key: "email",
  },
  {
    id: 7,
    key: "telephone",
  },

  {
    id: 8,
    key: "country",
  },
  {
    id: 9,
    key: "address",
  },
  {
    id: 10,
    key: "building_name",
  },

  {
    id: 11,
    key: "alley",
  },
  {
    id: 12,
    key: "road",
  },
  {
    id: 13,
    key: "province",
  },

  {
    id: 14,
    key: "district",
  },

  {
    id: 15,
    key: "sub_district",
  },
  {
    id: 16,
    key: "zip_code",
  },

  {
    id: 17,
    key: "is_default",
  },
  {
    id: 21,
    key: "branch_type",
  },
];
const province = [
  {
    id: 1,

    name: "กรุงเทพมหานคร",
  },
  {
    id: 2,

    name: "สมุทรปราการ",
  },
  {
    id: 3,

    name: "นนทบุรี",
  },
  {
    id: 4,

    name: "ปทุมธานี",
  },
  {
    id: 5,

    name: "พระนครศรีอยุธยา",
  },
  {
    id: 6,

    name: "อ่างทอง",
  },
  {
    id: 7,

    name: "ลพบุรี",
  },
  {
    id: 8,

    name: "สิงห์บุรี",
  },
  {
    id: 9,

    name: "ชัยนาท",
  },
  {
    id: 10,

    name: "สระบุรี",
  },
  {
    id: 11,

    name: "ชลบุรี",
  },
  {
    id: 12,

    name: "ระยอง",
  },
  {
    id: 13,

    name: "จันทบุรี",
  },
  {
    id: 14,

    name: "ตราด",
  },
  {
    id: 15,

    name: "ฉะเชิงเทรา",
  },
  {
    id: 16,

    name: "ปราจีนบุรี",
  },
  {
    id: 17,

    name: "นครนายก",
  },
  {
    id: 18,

    name: "สระแก้ว",
  },
  {
    id: 19,

    name: "นครราชสีมา",
  },
  {
    id: 20,

    name: "บุรีรัมย์",
  },
  {
    id: 21,

    name: "สุรินทร์",
  },
  {
    id: 22,

    name: "ศรีสะเกษ",
  },
  {
    id: 23,

    name: "อุบลราชธานี",
  },
  {
    id: 24,

    name: "ยโสธร",
  },
  {
    id: 25,

    name: "ชัยภูมิ",
  },
  {
    id: 26,

    name: "อำนาจเจริญ",
  },
  {
    id: 27,

    name: "หนองบัวลำภู",
  },
  {
    id: 28,

    name: "ขอนแก่น",
  },
  {
    id: 29,

    name: "อุดรธานี",
  },
  {
    id: 30,

    name: "เลย",
  },
  {
    id: 31,

    name: "หนองคาย",
  },
  {
    id: 32,

    name: "มหาสารคาม",
  },
  {
    id: 33,

    name: "ร้อยเอ็ด",
  },
  {
    id: 34,

    name: "กาฬสินธุ์",
  },
  {
    id: 35,

    name: "สกลนคร",
  },
  {
    id: 36,

    name: "นครพนม",
  },
  {
    id: 37,

    name: "มุกดาหาร",
  },
  {
    id: 38,

    name: "เชียงใหม่",
  },
  {
    id: 39,

    name: "ลำพูน",
  },
  {
    id: 40,

    name: "ลำปาง",
  },
  {
    id: 41,

    name: "อุตรดิตถ์",
  },
  {
    id: 42,

    name: "แพร่",
  },
  {
    id: 43,

    name: "น่าน",
  },
  {
    id: 44,

    name: "พะเยา",
  },
  {
    id: 45,

    name: "เชียงราย",
  },
  {
    id: 46,

    name: "แม่ฮ่องสอน",
  },
  {
    id: 47,

    name: "นครสวรรค์",
  },
  {
    id: 48,

    name: "อุทัยธานี",
  },
  {
    id: 49,

    name: "กำแพงเพชร",
  },
  {
    id: 50,

    name: "ตาก",
  },
  {
    id: 51,

    name: "สุโขทัย",
  },
  {
    id: 52,

    name: "พิษณุโลก",
  },
  {
    id: 53,

    name: "พิจิตร",
  },
  {
    id: 54,

    name: "เพชรบูรณ์",
  },
  {
    id: 55,

    name: "ราชบุรี",
  },
  {
    id: 56,

    name: "กาญจนบุรี",
  },
  {
    id: 57,

    name: "สุพรรณบุรี",
  },
  {
    id: 58,

    name: "นครปฐม",
  },
  {
    id: 59,

    name: "สมุทรสาคร",
  },
  {
    id: 60,

    name: "สมุทรสงคราม",
  },
  {
    id: 61,

    name: "เพชรบุรี",
  },
  {
    id: 62,

    name: "ประจวบคีรีขันธ์",
  },
  {
    id: 63,

    name: "นครศรีธรรมราช",
  },
  {
    id: 64,

    name: "กระบี่",
  },
  {
    id: 65,

    name: "พังงา",
  },
  {
    id: 66,

    name: "ภูเก็ต",
  },
  {
    id: 67,

    name: "สุราษฎร์ธานี",
  },
  {
    id: 68,

    name: "ระนอง",
  },
  {
    id: 69,

    name: "ชุมพร",
  },
  {
    id: 70,

    name: "สงขลา",
  },
  {
    id: 71,

    name: "สตูล",
  },
  {
    id: 72,

    name: "ตรัง",
  },
  {
    id: 73,

    name: "พัทลุง",
  },
  {
    id: 74,

    name: "ปัตตานี",
  },
  {
    id: 75,

    name: "ยะลา",
  },
  {
    id: 76,

    name: "นราธิวาส",
  },
  {
    id: 77,

    name: "บึงกาฬ",
  },
];
export {
  contactField,
  shippingField,
  billingField,
  mapBillingField,
  mapShippingField,
  mapContactField,
  province,
};
